import React from 'react';
import { motion } from 'framer-motion';
import { Facebook, Instagram, Linkedin, Mail, Phone, MapPin, ArrowRight } from 'lucide-react';

const Footer = ({ isDarkMode }) => {
  const scrollToProcess = () => {
    const servicesSection = document.getElementById('services-overview');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const scrollToBooking = () => {
    const bookingWidget = document.getElementById('booking-widget');
    if (bookingWidget) {
      bookingWidget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const scrollToTestimonials = () => {
    const testimonialsSection = document.getElementById('client-testimonials-section');
    if (testimonialsSection) {
      testimonialsSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const scrollToFAQ = () => {
    const faqSection = document.querySelector('section.py-16.md\\:py-32');
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const quickLinks = [
    { text: "Home", onClick: scrollToTop },
    { text: "Our Process", onClick: scrollToProcess },
    { text: "Contact Us", onClick: scrollToBooking },
    { text: "Testimonials", onClick: scrollToTestimonials },
    { text: "FAQ", onClick: scrollToFAQ }
  ];

  return (
    <footer className={`${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} py-16 relative overflow-hidden`}>
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-5">
        <svg width="100%" height="100%">
          <pattern id="footer-pattern" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
            <circle cx="20" cy="20" r="1" fill="#3B82F6" />
          </pattern>
          <rect x="0" y="0" width="100%" height="100%" fill="url(#footer-pattern)" />
        </svg>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12">
          {/* Brand Section */}
          <div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="inline-block mb-6"
            >
              <img 
                src="https://learn.trainandscale.com/wp-content/uploads/2024/03/ts-copy.png" 
                alt="Train & Scale Logo" 
                className="h-16 w-auto drop-shadow-lg hover:drop-shadow-2xl transition-all duration-300"  // Increased size and added shadow effects
              />
            </motion.div>
            <p className={`mb-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Empowering businesses with AI-driven solutions and strategic growth capital.
            </p>
            <div className="flex space-x-4">
              {[
                { icon: Facebook, link: "https://www.facebook.com/TheRealGabrealHindley?mibextid=LQQJ4d&mibextid=LQQJ4d" },
                { icon: Instagram, link: "https://www.instagram.com/trainandscalellc/profilecard/?igsh=NTc4MTIwNjQ2YQ==" },
                { icon: Linkedin, link: "https://www.linkedin.com/in/gabreal-hindley-74883616a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" }
              ].map((social, index) => (
                <motion.a
                  key={index}
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-blue-500'} transition-colors duration-300`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <social.icon size={20} />
                </motion.a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className={`text-lg font-semibold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Quick Links
            </h4>
            <ul className="space-y-3">
              {quickLinks.map((link, index) => (
                <li key={index}>
                  <motion.button
                    onClick={link.onClick}
                    className={`flex items-center ${
                      isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-blue-500'
                    } transition-colors duration-300 w-full text-left`}
                    whileHover={{ x: 5 }}
                  >
                    <ArrowRight className="w-4 h-4 mr-2" />
                    {link.text}
                  </motion.button>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className={`text-lg font-semibold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Contact
            </h4>
            <ul className="space-y-4">
              <li className="flex items-start">
                <Mail className={`w-5 h-5 mr-3 mt-1 ${isDarkMode ? 'text-gray-400' : 'text-blue-500'}`} />
                <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                  support@trainandscale.com
                </span>
              </li>
              <li className="flex items-start">
                <Phone className={`w-5 h-5 mr-3 mt-1 ${isDarkMode ? 'text-gray-400' : 'text-blue-500'}`} />
                <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                  (619) 381-8941
                </span>
              </li>
              <li className="flex items-start">
                <MapPin className={`w-5 h-5 mr-3 mt-1 ${isDarkMode ? 'text-gray-400' : 'text-blue-500'}`} />
                <span className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
                  Las Vegas, NV
                </span>
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          <div>
            <h4 className={`text-lg font-semibold mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Stay Updated
            </h4>
            <p className={`mb-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
              Subscribe to our newsletter for the latest updates and insights.
            </p>
            <div className="flex">
              <input
                type="email"
                placeholder="Enter your email"
                className={`flex-1 p-3 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
                }`}
              />
              <motion.button
                className="bg-blue-500 text-white px-4 rounded-r-lg hover:bg-blue-600 transition-colors duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <ArrowRight size={20} />
              </motion.button>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className={`pt-8 mt-8 border-t ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}`}>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
              © 2024 Train And Scale LLC. All rights reserved.
            </p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <a href="#" className={`${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-blue-500'} transition-colors duration-300`}>Privacy Policy</a>
              <a href="#" className={`${isDarkMode ? 'text-gray-400 hover:text-white' : 'text-gray-600 hover:text-blue-500'} transition-colors duration-300`}>Terms of Service</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;