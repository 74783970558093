import React, { createContext, useContext, useState, useEffect } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loadingStates, setLoadingStates] = useState({
    hero: false,
    critical: false,
  });
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const setLoadingState = (key, value) => {
    setLoadingStates((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (loadingStates.hero && loadingStates.critical) {
      const timer = setTimeout(() => {
        setIsInitialLoading(false);
      }, 500); // Adjusted for smoother transition
      return () => clearTimeout(timer);
    }
  }, [loadingStates]);

  // Fallback timeout
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isInitialLoading) {
        console.warn('Loading timeout reached - forcing completion');
        setLoadingState('hero', true);
        setLoadingState('critical', true);
        setIsInitialLoading(false);
      }
    }, 5000);

    return () => clearTimeout(timeout);
  }, [isInitialLoading]);

  return (
    <LoadingContext.Provider
      value={{
        loadingStates,
        setLoadingState,
        isInitialLoading,
        setIsInitialLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
