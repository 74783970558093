import React, { useEffect, Suspense, memo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import { LoadingProvider, useLoading } from './context/LoadingContext';

// Import components
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import ChatbotWidget from './components/ChatbotWidget';

// Lazy load heavy components
const Services1 = React.lazy(() => import('./components/Services1'));
const Services2alt = React.lazy(() => import('./components/Services2alt'));
const ServicesOverview = React.lazy(() => import('./components/ServicesOverview'));
const UpdatedAppointmentSetters = React.lazy(() => import('./components/UpdatedService8'));
const Services10 = React.lazy(() => import('./components/Services10'));
const Services12 = React.lazy(() => import('./components/Services12'));
const Testimonials = React.lazy(() => import('./components/Testimonials'));
const SalesCalendarDashboard = React.lazy(() => import('./components/SalesCalendarDashboard'));

// Memoize the services content to prevent unnecessary re-renders
const ServicesContent = memo(() => (
  <Suspense fallback={<div>Loading services...</div>}>
    <div className="services-content">
      <ServicesOverview />
      <Services12 />
      <Services1 />
      <Services10 />
      <Testimonials />
    </div>
  </Suspense>
));

const MainContent = () => {
  const { isInitialLoading, setLoadingState } = useLoading();

  // Set critical content as loaded once mounted
  useEffect(() => {
    // Ensure this only runs once
    const timer = setTimeout(() => {
      setLoadingState('critical', true);
    }, 100);
    return () => clearTimeout(timer);
  }, [setLoadingState]);

  // Set hero content as loaded
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingState('hero', true);
    }, 200);
    return () => clearTimeout(timer);
  }, [setLoadingState]);

  if (isInitialLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="app-container">
      <Header />
      <div className="content-wrapper">
        <Routes>
          <Route
            path="/"
            element={
              <main className="main-content">
                <Hero />
                <ServicesContent />
              </main>
            }
          />
          <Route
            path="/services"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <UpdatedAppointmentSetters />
              </Suspense>
            }
          />
          <Route
            path="/services8"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <UpdatedAppointmentSetters />
              </Suspense>
            }
          />
          <Route
            path="/calendar"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <SalesCalendarDashboard />
              </Suspense>
            }
          />
          <Route
            path="/services10"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Services10 />
              </Suspense>
            }
          />
          <Route
            path="/services12"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Services12 />
              </Suspense>
            }
          />
        </Routes>
      </div>
      <Footer />
      <ChatbotWidget />
    </div>
  );
};

const App = () => {
  return (
    <LoadingProvider>
      <Router>
        <MainContent />
      </Router>
    </LoadingProvider>
  );
};

export default App;
