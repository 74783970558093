import React, { useState, useRef, useEffect, useCallback } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import {
  ArrowRight,
  Zap,
  Globe,
  MessageSquare,
  Users,
  PhoneCall,
  Play,
  ChevronLeft,
  ChevronRight,
  Building2,
  X,
} from 'lucide-react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import ReactDOM from 'react-dom';

const VideoModal = ({ testimonial, onClose }) => {
  const isVimeoVideo = testimonial.videoUrl.includes('vimeo');

  const videoUrl = isVimeoVideo
    ? `${testimonial.videoUrl}&autoplay=1&muted=0`
    : testimonial.videoUrl;

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-75 z-[9999] flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: 'spring', duration: 0.5 }}
        className="relative w-full max-w-4xl bg-black rounded-xl overflow-hidden shadow-2xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative pt-[56.25%]">
          {isVimeoVideo ? (
            <iframe
              src={videoUrl}
              className="absolute top-0 left-0 w-full h-full"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              allowFullScreen
              title={testimonial.videoTitle || `${testimonial.name}'s Testimonial`}
            />
          ) : (
            <video
              src={testimonial.videoUrl}
              className="absolute top-0 left-0 w-full h-full object-cover"
              controls
              autoPlay
              muted={false}
            />
          )}
          <button
            className="absolute top-4 right-4 text-white hover:text-gray-200 transition-colors duration-200 bg-black/50 p-2 rounded-full hover:bg-black/70"
            onClick={onClose}
          >
            <X size={24} />
          </button>
        </div>
      </motion.div>
    </motion.div>,
    document.body
  );
};

const VSLModal = ({ onClose, currentTime }) => {
  const [isModalVideoLoaded, setIsModalVideoLoaded] = useState(false);
  const fullscreenPlayerRef = useRef(null);

  useEffect(() => {
    const initializeFullscreenPlayer = () => {
      if (window.Vimeo) {
        fullscreenPlayerRef.current = new window.Vimeo.Player('vsl-video-fullscreen');
        fullscreenPlayerRef.current
          .ready()
          .then(() => {
            fullscreenPlayerRef.current.setCurrentTime(currentTime);
            fullscreenPlayerRef.current.play();
            setIsModalVideoLoaded(true);
          })
          .catch((error) => {
            console.warn('Vimeo player ready error:', error);
          });
      }
    };

    initializeFullscreenPlayer();

    return () => {
      if (fullscreenPlayerRef.current) {
        fullscreenPlayerRef.current.unload();
      }
    };
  }, [currentTime]);

  return ReactDOM.createPortal(
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-75 z-[9999] flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: 'spring', duration: 0.5 }}
        className="relative w-full max-w-4xl bg-black rounded-xl overflow-hidden shadow-2xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative pt-[56.25%]">
          {!isModalVideoLoaded && (
            <div className="absolute inset-0 bg-black flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          )}
          <iframe
            id="vsl-video-fullscreen"
            src={`https://player.vimeo.com/video/1031160579?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=0&controls=1&dnt=1&playsinline=1&quality=auto&transparent=1&pip=0&download=0&background=0&color=4299E1`}
            className="absolute top-0 left-0 w-full h-full"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Train And Scale LLC"
          />
          <button
            className="absolute top-4 right-4 text-white hover:text-gray-200 transition-colors duration-200 bg-black/50 p-2 rounded-full hover:bg-black/70"
            onClick={onClose}
          >
            <X size={24} />
          </button>
        </div>
      </motion.div>
    </motion.div>,
    document.body
  );
};

const PhoneDisplay = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [showTestimonialVideo, setShowTestimonialVideo] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [videoPlayer, setVideoPlayer] = useState(null);
  const playerRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoVolume, setVideoVolume] = useState(1);
  const [showFullscreenVideo, setShowFullscreenVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPreloading, setIsPreloading] = useState(true);

  const testimonials = [
    {
      id: 1,
      name: 'Colt Mosdef',
      company: 'Reward Over Risk LLC',
      text: 'I used to do $10,000 per month, now I do $10,000 every single week.',
      image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
      videoUrl:
        'https://player.vimeo.com/video/1031079685?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      role: 'Founder & CEO',
      videoTitle: 'Colt Mosdef: Founder of Reward Over Risk LLC',
    },
    {
      id: 2,
      name: 'Jamal Robinson',
      company: 'Fojo Digital LLC',
      text: "When starting with Train & Scale, we were stuck hovering between the $10,000 to $20,000 per month mark, but Train & Scale helped us to actually surpass the $50,000 per month mark, and now we're hitting that consistently.",
      image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
      videoUrl:
        'https://player.vimeo.com/video/1031081061?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      role: 'Co-Founder & CEO',
      videoTitle: 'Jamal Robinson: Founder of Fojo Digital LLC',
    },
    {
      id: 3,
      name: 'Peter Zhang',
      company: 'Steady Growth LLC',
      text: "From September to February we're almost at $100,000 in revenue. I'm very proud of the work.",
      image: 'https://i.ibb.co/25HF7qz/IMG-0749.jpg',
      videoUrl:
        'https://player.vimeo.com/video/1031077338?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      role: 'Founder & CEO',
      videoTitle: 'Peter Zhang: Founder of Steady Growth LLC',
    },
  ];

  useEffect(() => {
    // Ensure the Vimeo Player API script is loaded
    const initializePlayer = () => {
      if (!playerRef.current && window.Vimeo) {
        const player = new window.Vimeo.Player('vsl-video', {
          id: 1031160579,
          background: 1,
          autoplay: false,
          muted: false,
          controls: false,
          loop: false,
          dnt: true,
          playsinline: true,
          quality: 'auto',
          pip: false,
        });

        playerRef.current = player;
        setVideoPlayer(player);

        // Event listeners
        player.on('play', () => setIsVideoPlaying(true));
        player.on('pause', () => setIsVideoPlaying(false));
        player.on('timeupdate', (data) => setVideoTime(data.seconds));

        player.on('loaded', () => {
          setIsLoading(false);
          setIsPreloading(false);
          player.pause();
        });

        // Get duration and volume
        player.getDuration().then(setVideoDuration);
        player.getVolume().then(setVideoVolume);

        // Set current time to 0 and pause
        player.setCurrentTime(0).then(() => player.pause());
      }
    };

    if (window.Vimeo) {
      initializePlayer();
    } else {
      const vimeoScript = document.createElement('script');
      vimeoScript.src = 'https://player.vimeo.com/api/player.js';
      vimeoScript.async = true;
      vimeoScript.onload = initializePlayer;
      document.head.appendChild(vimeoScript);
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.off('play');
        playerRef.current.off('pause');
        playerRef.current.off('timeupdate');
        playerRef.current.off('loaded');
        playerRef.current.unload();
        playerRef.current = null;
      }
    };
  }, []);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handlePlayPause = useCallback(() => {
    if (playerRef.current) {
      if (isVideoPlaying) {
        playerRef.current.pause();
      } else {
        playerRef.current
          .setMuted(false)
          .then(() => {
            playerRef.current.play();
          })
          .catch((error) => {
            console.warn('Error unmuting video:', error);
            playerRef.current.play();
          });
      }
    }
  }, [isVideoPlaying]);

  const handleSeek = useCallback(
    (e) => {
      if (playerRef.current && videoDuration) {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const percentage = x / rect.width;
        playerRef.current.setCurrentTime(percentage * videoDuration);
      }
    },
    [videoDuration]
  );

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    if (playerRef.current) {
      playerRef.current.setVolume(newVolume);
      setVideoVolume(newVolume);
    }
  };

  const handleFullscreen = () => {
    if (playerRef.current) {
      playerRef.current
        .pause()
        .then(() => {
          const currentTime = videoTime;
          setShowFullscreenVideo(true);
        })
        .catch((error) => {
          console.warn('Error pausing video:', error);
          setShowFullscreenVideo(true);
        });
    }
  };

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    if (!document.getElementById('modal-root')) {
      const modalRoot = document.createElement('div');
      modalRoot.id = 'modal-root';
      document.body.appendChild(modalRoot);
    }
  }, []);

  const videoSrc =
    'https://player.vimeo.com/video/1031160579?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=0&muted=0&controls=0&dnt=1&playsinline=1&quality=auto&transparent=1&pip=0&download=0&background=1';

  const memoizedTestimonials = testimonials;

  return (
    <div className="bg-black size-full absolute z-10 overflow-hidden border-[8px] border-[#4299E1] flex flex-col items-center justify-start rounded-[40px] shadow-2xl">
      <div className="w-full h-1/2 relative bg-black flex flex-col">
        <div className="flex-grow relative">
          {isPreloading && (
            <div className="absolute inset-0 bg-black flex items-center justify-center z-10">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          )}
          <div className="relative w-full h-full">
            <iframe
              id="vsl-video"
              src={videoSrc}
              className="absolute top-0 left-0 w-full h-full"
              frameBorder="0"
              allowFullScreen
              allow="autoplay; fullscreen; picture-in-picture"
              title="Train And Scale LLC"
              loading="eager"
              fetchpriority="high"
            />
          </div>
          {isLoading && (
            <div className="absolute inset-0 bg-black flex items-center justify-center">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          )}
        </div>

        <div className="h-16 bg-black flex flex-col justify-center px-4 group">
          <div
            className="w-full h-1 bg-gray-700 rounded-full mb-3 cursor-pointer hover:h-1.5 transition-all"
            onClick={handleSeek}
          >
            <div
              className="h-full bg-blue-500 rounded-full"
              style={{ width: `${(videoTime / videoDuration) * 100}%` }}
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <button className="text-white hover:text-blue-500 transition-colors" onClick={handlePlayPause}>
                {isVideoPlaying ? (
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <rect x="6" y="4" width="4" height="16" />
                    <rect x="14" y="4" width="4" height="16" />
                  </svg>
                ) : (
                  <Play className="w-6 h-6" />
                )}
              </button>
              <span className="text-white text-sm">
                {formatTime(videoTime)} / {formatTime(videoDuration)}
              </span>
            </div>

            <div className="flex items-center space-x-4">
              <div className="group/volume flex items-center">
                <button className="text-white hover:text-blue-500 transition-colors">
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15.536 8.464a5 5 0 010 7.072M18.364 5.636a9 9 0 010 12.728M5 8.5h2l4-4v15l-4-4H5a1 1 0 01-1-1v-5a1 1 0 011-1z"
                    />
                  </svg>
                </button>
                <div className="w-0 group-hover/volume:w-20 overflow-hidden transition-all duration-300">
                  <input
                    type="range"
                    className="w-16 mx-2"
                    min="0"
                    max="1"
                    step="0.1"
                    value={videoVolume}
                    onChange={handleVolumeChange}
                  />
                </div>
              </div>

              <button className="text-white hover:text-blue-500 transition-colors" onClick={handleFullscreen}>
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 8V6a2 2 0 012-2h2M4 16v2a2 2 0 002 2h2m8-16h2a2 2 0 012 2v2m-2 12h2a2 2 0 002-2v-2"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-1/2 bg-gradient-to-br from-blue-50 to-white p-4">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">Client Success Stories</h3>

        <div className="relative h-[calc(100%-2rem)] overflow-hidden rounded-xl">
          <motion.div
            className="absolute inset-0 flex items-center"
            animate={{ x: `-${currentTestimonial * 100}%` }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            {memoizedTestimonials.map((testimonial) => (
              <div
                key={testimonial.id}
                className="w-full h-full flex-shrink-0 p-4 relative"
                style={{ backgroundColor: 'rgba(219, 234, 254, 0.3)' }}
              >
                <div className="flex items-start space-x-4">
                  <div
                    className="relative w-20 h-20 flex-shrink-0 cursor-pointer group"
                    onClick={() => {
                      setSelectedTestimonial(testimonial);
                      setShowTestimonialVideo(true);
                    }}
                  >
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-30 group-hover:bg-opacity-40 transition-all duration-200 rounded-lg flex items-center justify-center">
                      <Play className="w-8 h-8 text-white opacity-80 group-hover:opacity-100 group-hover:scale-110 transition-all duration-200" />
                    </div>
                  </div>

                  <div className="flex-1">
                    <p className="text-sm text-gray-700 italic mb-2">"{testimonial.text}"</p>
                    <div className="text-sm">
                      <p className="font-semibold text-gray-900">{testimonial.name}</p>
                      <p className="text-blue-600">{testimonial.role}</p>
                      <p className="text-gray-500 text-xs">{testimonial.company}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>

          <div className="absolute bottom-2 left-0 right-0 flex justify-center space-x-2">
            {memoizedTestimonials.map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 rounded-full transition-all duration-200 ${
                  currentTestimonial === index ? 'bg-blue-500 w-4' : 'bg-blue-300 hover:bg-blue-400'
                }`}
                onClick={() => setCurrentTestimonial(index)}
              />
            ))}
          </div>

          <button
            onClick={prevTestimonial}
            className="absolute left-2 top-1/2 -translate-y-1/2 p-1 rounded-full bg-white/80 hover:bg-white text-blue-500 shadow-lg transition-all duration-200 hover:scale-110"
          >
            <ChevronLeft size={20} />
          </button>
          <button
            onClick={nextTestimonial}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1 rounded-full bg-white/80 hover:bg-white text-blue-500 shadow-lg transition-all duration-200 hover:scale-110"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>

      {showTestimonialVideo && selectedTestimonial && (
        <VideoModal
          testimonial={selectedTestimonial}
          onClose={() => setShowTestimonialVideo(false)}
        />
      )}

      <AnimatePresence>
        {showFullscreenVideo && (
          <VSLModal
            onClose={() => {
              setShowFullscreenVideo(false);
              if (videoPlayer) {
                videoPlayer.play();
              }
            }}
            currentTime={videoTime}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const Hero = ({ isDarkMode }) => {
  const phoneRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  const services = [
    { icon: Building2, text: 'Strategic Funding Options | Up to $5M Available' },
    { icon: Zap, text: 'An Irresistible Offer (If Needed)' },
    { icon: Globe, text: 'High Converting Website Built With Fortune 500 Technologies' },
    { icon: MessageSquare, text: 'Sales Automations and Frameworks' },
    { icon: Users, text: 'Sales Management' },
  ];

  const additionalServices = [
    { icon: PhoneCall, title: 'Dedicated Sales Manager', description: 'Weekly 1-on-1 Calls' },
    {
      icon: MessageSquare,
      title: '3,000+ Strategic Messages',
      description: (
        <div className="flex flex-col">
          <div className="flex items-center space-x-4 mt-1">
            {[FaFacebook, FaInstagram, FaLinkedin].map((Icon, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.2, rotate: 5 }}
                whileTap={{ scale: 0.9 }}
              >
                <Icon
                  size={16}
                  className={`${
                    isDarkMode ? 'text-gray-400' : 'text-gray-500'
                  } hover:text-blue-500 transition-colors duration-200 cursor-pointer`}
                />
              </motion.div>
            ))}
          </div>
        </div>
      ),
    },
    { icon: Globe, title: 'Premium Website', description: 'Next.js Technologies' },
  ];

  const gradientAnimation = {
    initial: {
      backgroundPosition: '0% 50%',
      backgroundSize: '200% 200%',
    },
    animate: {
      backgroundPosition: ['0% 50%', '100% 50%', '50% 100%', '0% 50%'],
      transition: {
        duration: 20,
        ease: 'linear',
        repeat: Infinity,
      },
    },
  };

  const scrollToBooking = () => {
    const bookingSection = document.getElementById('booking-widget');
    if (bookingSection) {
      bookingSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const scrollToHowItWorks = () => {
    const servicesSection = document.getElementById('services-overview');
    if (servicesSection) {
      servicesSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <motion.section
      className={`min-h-screen pt-32 sm:pt-40 md:pt-35 pb-16 md:pb-0 flex items-center relative overflow-hidden ${
        isDarkMode
          ? 'bg-gray-900'
          : 'bg-gradient-to-br from-blue-50/80 via-white to-blue-100/80'
      }`}
      initial="initial"
      animate="animate"
      variants={gradientAnimation}
      style={{
        background: isDarkMode
          ? 'linear-gradient(45deg, #1a202c, #2d3748, #1a202c)'
          : 'linear-gradient(-45deg, rgba(239,246,255,0.8), rgba(255,255,255,1), rgba(219,234,254,0.8), rgba(239,246,255,0.9))',
        backgroundSize: '400% 400%',
      }}
    >
      <motion.div
        className="absolute inset-0 opacity-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 2 }}
      >
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(59,130,246,0.1),transparent)]" />
        <svg width="100%" height="100%">
          <pattern
            id="pattern-circles"
            x="0"
            y="0"
            width="40"
            height="40"
            patternUnits="userSpaceOnUse"
            patternContentUnits="userSpaceOnUse"
          >
            <circle
              id="pattern-circle"
              cx="20"
              cy="20"
              r="1"
              fill={isDarkMode ? '#4299E1' : '#3B82F6'}
              className="animate-pulse"
            />
          </pattern>
          <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-circles)" />
        </svg>

        <div className="absolute inset-0">
          {Array.from({ length: 20 }).map((_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 bg-blue-400/20 rounded-full animate-float"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`,
                animationDuration: `${15 + Math.random() * 10}s`,
              }}
            />
          ))}
        </div>
      </motion.div>

      <div className="container mx-auto px-4 md:px-6 flex flex-col lg:flex-row items-center justify-between relative z-10">
        <motion.div
          className="w-full lg:w-1/2 mb-16 lg:mb-0 space-y-6 md:space-y-8"
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } },
          }}
        >
          <motion.h1
             className={`text-[2.4rem] leading-[1.1] sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6 ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            } font-sans`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <span className="text-blue-500">Scale</span> Your Business with{' '}
            <span className="text-blue-500">Proven Solutions</span>
          </motion.h1>
          <motion.h2
            className={`text-xl sm:text-2xl md:text-3xl font-semibold mb-4 md:mb-6 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            } font-serif`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            In just 14 days, we'll provide:
          </motion.h2>
          <div className="space-y-3 md:space-y-4 mb-8 md:mb-8">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className={`flex items-center p-3 md:p-4 rounded-lg ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                } shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1`}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.1 * index }}
                whileHover={{ scale: 1.03 }}
              >
                <div className="mr-4 p-2 rounded-full bg-blue-500 text-white">
                  <service.icon size={24} />
                </div>
                <span
                  className={`${
                    isDarkMode ? 'text-white' : 'text-gray-800'
                  } text-lg font-medium font-sans`}
                >
                  {service.text}
                </span>
              </motion.div>
            ))}
          </div>
          <motion.p
            className={`text-lg mb-8 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'} font-sans`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          ></motion.p>
          <div className="flex flex-col sm:flex-row gap-3 md:gap-4 mb-6 md:mb-8">
            <motion.button
              onClick={scrollToBooking}
              className="w-full sm:w-auto px-6 py-4 rounded-full font-bold text-white text-lg shadow-lg flex items-center justify-center bg-blue-500 hover:bg-blue-600 transition-colors duration-300"
              whileHover={{ scale: 1.05, boxShadow: '0 0 15px rgba(66, 153, 225, 0.5)' }}
              whileTap={{ scale: 0.95 }}
            >
              Book a Free Strategy Call <ArrowRight className="ml-2" size={20} />
            </motion.button>
            <motion.button
              onClick={scrollToHowItWorks}
              className={`w-full sm:w-auto px-6 py-4 rounded-full font-bold text-lg shadow-lg flex items-center justify-center ${
                isDarkMode
                  ? 'bg-gray-800 text-white hover:bg-gray-700'
                  : 'bg-white text-blue-500 hover:bg-gray-50'
              } transition-colors duration-300`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              See How It Works
            </motion.button>
          </div>
          <div className="flex flex-wrap gap-3 md:gap-4">
            {additionalServices.map((service, index) => (
              <motion.div
                key={index}
                className={`flex items-center p-2.5 md:p-3 rounded-lg ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                } shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
                whileHover={{ scale: 1.03 }}
              >
                <service.icon className="mr-3 text-blue-500" size={24} />
                <div className="flex flex-col">
                  <span
                    className={`${
                      isDarkMode ? 'text-white' : 'text-gray-800'
                    } text-md font-medium font-sans`}
                  >
                    {service.title}
                  </span>
                  {service.description && (
                    <span
                      className={`${
                        isDarkMode ? 'text-gray-400' : 'text-gray-600'
                      } text-sm font-sans`}
                    >
                      {service.description}
                    </span>
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
        <motion.div
          className="w-full lg:w-1/2 mt-8 lg:mt-0 px-4 md:px-0"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="flex justify-center lg:justify-end">
            <div style={{ perspective: '1000px', transform: 'translateZ(0)' }}>
              <motion.div
                ref={phoneRef}
                className="w-[260px] sm:w-[320px] md:w-[380px] will-change-transform"
                style={{
                  borderRadius: '40px',
                  transform: 'rotateY(-25deg) rotateZ(8deg)',
                  transition: 'transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                  aspectRatio: '1 / 2.135',
                }}
                whileHover={{
                  scale: 1.05,
                  rotateY: '-15deg',
                  rotateZ: '5deg',
                  transition: { duration: 0.5, ease: 'easeInOut' },
                }}
              >
                <PhoneDisplay />
                <div
                  style={{
                    borderRadius: '48px 48px 56px',
                    position: 'absolute',
                    inset: '0px -10.0533px 0px 0px',
                    boxShadow: 'rgba(66, 153, 225, 0.5) 0px 0px 0px 30px inset',
                    background:
                      'linear-gradient(145deg, rgba(66, 153, 225, 0.4) 0%, rgba(66, 153, 225, 0) 100%)',
                  }}
                ></div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Hero;