import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLoading } from '../context/LoadingContext';

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);
  const { isInitialLoading } = useLoading();

  useEffect(() => {
    if (isInitialLoading) {
      const interval = setInterval(() => {
        setProgress(prev => {
          const newProgress = prev + 0.5;
          if (newProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return newProgress;
        });
      }, 50);

      return () => clearInterval(interval);
    }
  }, [isInitialLoading]);

  return (
    <AnimatePresence mode="wait">
      {isInitialLoading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed inset-0 bg-[#030407] z-50 flex items-center justify-center"
        >
          <div className="relative z-10 w-full max-w-md px-6 sm:px-4">
            {/* Logo */}
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="flex justify-center mb-14 sm:mb-14 md:mb-14 mt-[-2rem] sm:mt-[-3rem]"
            >
              <img
                src="https://learn.trainandscale.com/wp-content/uploads/2024/03/ts-copy.png"
                alt="Train & Scale"
                className="w-56 sm:w-64 md:w-72"
              />
            </motion.div>

            {/* Loading Bar Container */}
            <div className="space-y-6">
              {/* Professional Tagline */}
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-center mb-8"
              >
                <div className="text-[#4299E1]/60 text-sm sm:text-base tracking-[0.15em] font-light mb-2">
                  EXCLUSIVELY FOR
                </div>
                <div className="text-[#4299E1]/90 text-lg sm:text-xl tracking-wide font-light">
                  Coaches · Consultants · Agency Owners
                </div>
              </motion.div>

              {/* Progress Bar */}
              <div className="relative">
                <motion.div
                  className="h-0.5 w-full bg-blue-950/30 rounded-full overflow-hidden backdrop-blur-sm"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <motion.div
                    className="h-full bg-gradient-to-r from-blue-600 via-blue-500 to-blue-600"
                    style={{ width: `${progress}%` }}
                    transition={{ duration: 0.3 }}
                  />
                </motion.div>
              </div>

              {/* Loading Status */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex justify-center"
              >
                <div className="text-blue-500/40 text-xs sm:text-sm tracking-[0.2em] uppercase font-light">
                  Preparing Your Experience
                </div>
              </motion.div>
            </div>
          </div>

          {/* Subtle Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent pointer-events-none" />
          
          {/* Premium Corner Accent */}
          <div className="absolute top-0 left-0 w-32 h-32 bg-gradient-to-br from-blue-500/5 to-transparent" />
          <div className="absolute bottom-0 right-0 w-32 h-32 bg-gradient-to-tl from-blue-500/5 to-transparent" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingScreen; 