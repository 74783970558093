import React, { useEffect } from 'react';

const ChatbotWidget = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.id = 'answer-bot-embed';
    script.setAttribute('data-id', 'cefd072662');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://app.answeringapp.com/widget-loader.js';

    // Append script to document
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      // Remove script when component unmounts
      if (document.getElementById('answer-bot-embed')) {
        document.body.removeChild(script);
      }
    };
  }, []); // Empty dependency array means this runs once on mount

  return null; // This component doesn't render anything
};

export default ChatbotWidget; 