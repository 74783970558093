import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Menu, X, Phone, Calendar, Users, BarChart } from 'lucide-react';

const CustomIcon = ({ d, color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d={d} fill={color} />
  </svg>
);

const NavItem = ({ item, setActiveDropdown, activeDropdown }) => {
  return (
    <div className="relative group">
      <button
        className="flex items-center space-x-1 text-base font-medium text-gray-700 hover:text-blue-600 transition-colors duration-200"
        onClick={() => {
          if (item.onClick) {
            item.onClick();
          }
          setActiveDropdown(activeDropdown === item.name ? null : item.name);
        }}
      >
        <span>{item.name}</span>
        {item.dropdown && <ChevronDown size={16} />}
      </button>
      <AnimatePresence>
        {item.dropdown && activeDropdown === item.name && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute left-0 mt-2 w-64 bg-white rounded-md shadow-lg py-1 z-10"
          >
            {item.dropdown.map((subItem, index) => (
              <a
                key={index}
                href={subItem.link}
                className="flex items-center px-4 py-3 text-sm text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors duration-200"
              >
                <div className="mr-3">
                  <CustomIcon d={subItem.iconPath} color={subItem.color} />
                </div>
                {subItem.name}
              </a>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const AdvancedNavbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToBooking = () => {
    const bookingWidget = document.getElementById('booking-widget');
    if (bookingWidget) {
      bookingWidget.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const scrollToProcess = () => {
    const servicesSection = document.getElementById('services-overview');
    if (servicesSection) {
      servicesSection.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const scrollToTestimonials = () => {
    const testimonialsSection = document.getElementById('client-testimonials-section');
    if (testimonialsSection) {
      testimonialsSection.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  };

  const scrollToFAQ = () => {
    const faqSection = document.querySelector('section.py-16.md\\:py-32');
    if (faqSection) {
      faqSection.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  };

  const navItems = [
    { name: 'Home', link: '#home' },
    { 
      name: 'Our Process', 
      link: '#services-overview', 
      onClick: scrollToProcess
    },
    { 
      name: 'Contact Us', 
      link: '#booking-widget',
      onClick: scrollToBooking
    },
    { 
      name: 'Testimonials', 
      link: '#client-testimonials-section',
      onClick: scrollToTestimonials
    },
    { 
      name: 'FAQ', 
      link: '#faq-section',
      onClick: scrollToFAQ
    }
  ];

  return (
    <>
      <motion.nav
        className={`${isSticky ? 'fixed' : 'absolute'} top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled || isSticky ? 'bg-white shadow-lg' : 'bg-transparent'
        }`}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-3 md:py-4">
            <motion.div 
              className="flex items-center bg-gray-800 rounded-full px-4 py-2"
              whileHover={{ scale: 1.05 }}
            >
              <img 
                src="https://learn.trainandscale.com/wp-content/uploads/2024/03/ts-copy.png" 
                alt="Train & Scale Logo" 
                className="h-10 w-auto" 
              />
            </motion.div>

            <div className="hidden md:flex items-center space-x-8">
              {navItems.map((item) => (
                <NavItem
                  key={item.name}
                  item={item}
                  setActiveDropdown={setActiveDropdown}
                  activeDropdown={activeDropdown}
                />
              ))}
            </div>

            <div className="flex items-center space-x-4">
              <motion.button
                onClick={scrollToBooking}
                className="hidden sm:inline-flex px-6 py-3 rounded-full font-semibold text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200 text-base shadow-md"
                whileHover={{ scale: 1.05, boxShadow: '0 0 15px rgba(59, 130, 246, 0.5)' }}
                whileTap={{ scale: 0.95 }}
              >
                Book a Free Strategy Call
              </motion.button>
              <motion.button
                onClick={scrollToBooking}
                className="inline-flex sm:hidden items-center justify-center w-10 h-10 rounded-full bg-blue-600 text-white shadow-md"
                whileHover={{ scale: 1.05, boxShadow: '0 0 15px rgba(59, 130, 246, 0.5)' }}
                whileTap={{ scale: 0.95 }}
              >
                <Phone size={20} />
              </motion.button>
              <button
                className="md:hidden text-gray-700 hover:text-blue-600 transition-colors duration-200"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="md:hidden fixed inset-x-0 top-[4.5rem] bg-white shadow-lg overflow-hidden max-h-[calc(100vh-4.5rem)] overflow-y-auto z-50"
            >
              <div className="container mx-auto px-4 py-4">
                {navItems.map((item) => (
                  <motion.div 
                    key={item.name}
                    whileTap={{ scale: 0.98 }}
                    className="border-b border-gray-100 last:border-0"
                  >
                    <a
                      href={item.link}
                      onClick={(e) => {
                        e.preventDefault();
                        item.onClick?.();
                        setIsMenuOpen(false);
                      }}
                      className="flex items-center justify-between py-4 text-base font-medium text-gray-800 hover:text-blue-600 transition-colors duration-200 active:text-blue-700"
                    >
                      <span>{item.name}</span>
                      <ChevronDown 
                        size={16} 
                        className="text-gray-400 transform -rotate-90"
                      />
                    </a>
                  </motion.div>
                ))}

                {/* CTA Button */}
                <div className="mt-6 space-y-4">
                  <motion.button
                    onClick={() => {
                      scrollToBooking();
                      setIsMenuOpen(false);
                    }}
                    className="w-full px-6 py-4 rounded-full font-semibold text-white bg-blue-600 hover:bg-blue-700 transition-all duration-200 text-base shadow-md flex items-center justify-center space-x-2"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Phone size={18} />
                    <span>Book a Free Strategy Call</span>
                  </motion.button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-2 bg-gray-100">
          {/* Mobile view (stacked) */}
          <div className="md:hidden">
            <div className="flex items-center justify-between overflow-x-auto whitespace-nowrap py-0.5 text-sm text-gray-600">
              <div className="flex items-center space-x-4 px-1">
                <span className="flex items-center">
                  <Calendar size={14} className="mr-1.5 text-blue-600" />
                  <span className="font-medium">14-day setup</span>
                </span>
                <span className="flex items-center">
                  <Users size={14} className="mr-1.5 text-blue-600" />
                  <span className="font-medium">Expert team</span>
                </span>
                <span className="flex items-center">
                  <BarChart size={14} className="mr-1.5 text-blue-600" />
                  <span className="font-medium">Strategic Funding</span>
                </span>
              </div>
            </div>
          </div>

          {/* Desktop view (horizontal) - simplified */}
          <div className="hidden md:block">
            <div className="flex justify-between items-center text-sm text-gray-600">
              <div className="flex items-center space-x-4">
                <span className="flex items-center"><Calendar size={16} className="mr-1" /> Fast 14-day setup</span>
                <span className="flex items-center"><Users size={16} className="mr-1" /> Industry Leaders Since 2017</span>
              </div>

              <div className="flex items-center">
                <span className="flex items-center"><BarChart size={16} className="mr-1" /> Strategic Funding</span>
              </div>
            </div>
          </div>
        </div>
      </motion.nav>
    </>
  );
};

export default AdvancedNavbar;